import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { FluentRuleCustomizer, ValidationRules } from 'aurelia-validation';

@autoinject
export class StandardValidationBuilder {
  constructor(private I18N: I18N) {}

  with<T>(model: T) {
    return new ValidationBuilder(this.I18N, model);
  }
}

type WhenPredicate<T> = (model: T) => boolean;

class ValidationBuilder<T> {
  private _validations: FluentRuleCustomizer<any, any>;

  constructor(
    private I18N: I18N,
    private model: T
  ) {
    this._validations = ValidationRules.ensure('').satisfies(() => true);
  }

  get rules() {
    return this._validations.rules;
  }

  required(f: keyof typeof this.model, when?: WhenPredicate<T>) {
    let validation = this._validations.ensure(f as string).required();

    if (when) {
      validation = validation.when(when);
    }

    validation = validation.withMessage(this.I18N.tr('general.requiredField'));
    this._validations = validation;
    return this;
  }

  min(f: keyof typeof this.model, min: number, when?: WhenPredicate<T>) {
    let validation = this._validations.ensure(f as string).min(min);

    if (when) {
      validation = validation.when(when);
    }

    validation = validation.withMessage(this.I18N.tr('validation.mustBeGreaterOrEqualToValue', { value: min }));
    this._validations = validation;
    return this;
  }

  max(f: keyof typeof this.model, max: number, when?: WhenPredicate<T>) {
    let validation = this._validations.ensure(f as string).max(max);

    if (when) {
      validation = validation.when(when);
    }

    validation = validation.withMessage(this.I18N.tr('validation.mustBeLessOrEqualToValue', { value: max }));
    this._validations = validation;
    return this;
  }

  custom(
    f: keyof typeof this.model,
    message: string,
    predicate: (value: unknown, object: T) => boolean,
    when?: WhenPredicate<T>
  ) {
    let validation = this._validations.ensure(f as string).satisfies(predicate);

    if (when) {
      validation = validation.when(when);
    }

    validation = validation.withMessage(this.I18N.tr(message));
    this._validations = validation;
    return this;
  }

  done() {
    return this._validations.on(this.model);
  }

  on(model: T) {
    return this._validations.on(model);
  }
}
